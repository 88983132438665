import React from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

const Donate = () => {
  return (
    <div className="min-h-screen">
      <Link className="p-4 sm:text-xs md:text-xl" to="/">
        <button className="p-4" type="button">
          <BsArrowLeft />
        </button>
      </Link>
      <div className="flex flex-wrap  ">
        <div className="bg-main-bg   h-44 rounded-xl w-full lg:w-90 p-8 pt-9 m-3 ">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-2xl  text-cyan-800">Donate</p>
              <p className=" md:mx-24 sm:text-xs md:text-xl mt-12">
                Thank you for wanting to contribute to AAN. <br /> We'll be
                establishing a donation method soon. In the meantime - check out
                our{" "}
                <Link className="text-cyan-800" to="/howtohelp">
                  "How to Help"
                </Link>{" "}
                page for some other amazing organizations to donate to.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
