import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/AANlogo.png";
import { useForm, FormProvider } from "react-hook-form";
import StateSelectField from "../components/StateSelectField";

const Welcome = () => {
  const methods = useForm();
  const navigate = useNavigate();

  return (
    <div className="container min-w-full min-h-screen bg-half-transparent">
      <div className="shadow md:m-10 relative bg-main-bg rounded-3xl  box-border p-4 border-4 ">
        <div className="mt-1 grid grid-cols-2 md:grid-cols-4 gap-1 md:gap-4 justify-items-center ">
          <Link className="  text-gray-400 " to="/about">
            <div className="w-auto sm:h-5 box-border md:w-40 md:h-10   border-4 rounded-xl bg-secondary-dark-bg">
              <p className="text-xs md:text-base p-1 text-center">
                Why We're Here
              </p>
            </div>
          </Link>
          <Link className=" text-gray-400" to="/howtohelp">
            <div className="w-28 sm:h-5 box-border md:w-40 md:h-10 border-4 rounded-xl bg-secondary-dark-bg">
              <p className="text-xs md:text-base p-1 text-center">
                How To Help
              </p>
            </div>
          </Link>
          <Link className=" text-gray-400" to="/volunteer">
            <div className="w-28 sm:h-5 box-border md:w-40 md:h-10 border-4 rounded-xl bg-secondary-dark-bg">
              <p className="text-xs md:text-base p-1 text-center">Volunteer</p>
            </div>
          </Link>
          <Link className=" text-gray-400" to="/donate">
            <div className="w-28 sm:h-5 box-border md:w-40 md:h-10 border-4 rounded-xl bg-secondary-dark-bg">
              <p className="text-xs md:text-base p-1 text-center">Donate</p>
            </div>
          </Link>
        </div>
        <img
          className="w-48 md:w-80 md:mt-24 mt-5 mx-auto"
          src={logo}
          alt="abortion access now"></img>
        <div className=" mx-5 md:mx-12 font-body text-center text-2xl text-cyan-800">
          Tell us where you live, we'll link you to the organizations that'll
          help you get care.
        </div>
        <div className="my-10  text-center">
          <FormProvider {...methods}>
            <form>
              <StateSelectField
                name="jurisdiction"
                placeholder=""
                label="jurisdiction"
                whatItIs="Select A State"
                onSelectChange={({ value, properName }) =>
                  navigate(`/jurisdiction/${value}`, { state: { properName } })
                }
              />
            </form>
          </FormProvider>
        </div>

        <p className="font-bold text-center mt-24 text-xl">
          Get In Touch:{" "}
          <a
            className="text-cyan-800"
            href="mailto: info@abortionaccessnow.org">
            info@abortionaccessnow.org
          </a>
        </p>
      </div>
    </div>
  );
};

export default Welcome;
