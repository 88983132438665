import React from "react";
import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import { useStateContext } from "../contexts/ContextProvider";
import { MdOutlineCancel } from "react-icons/md";

const Sidebar = ({ location, data }) => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();

  const handleCloseSideBar = () => {
    if (screenSize <= 1000) {
      setActiveMenu((prevActiveMenu) => !prevActiveMenu);
    }
  };

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight  text-slate-900">
              Back
            </Link>
            <button
              type="button"
              onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
              className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden">
              <MdOutlineCancel />
            </button>
          </div>
          <div className="mt-10">
            <div>
              {data.map((item) => (
                <Scroll
                  key={item.label}
                  className={
                    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-cyan-800  hover:bg-light-gray m-2"
                  }
                  onClick={handleCloseSideBar}
                  to={item.path}
                  spy={true}
                  smooth={true}
                  delay={100}
                  offset={-30}
                  duration={500}>
                  {item.label}
                </Scroll>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
