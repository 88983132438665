import React from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { useStateContext } from "../contexts/ContextProvider";

const ShowBackArrow = () => {
  const { screenSize } = useStateContext();
  if (screenSize <= 1000) {
    return (
      <Link className="mt-2 ml-2  md:ml-3 fixed" to="/">
        <button className="" type="button">
          <BsArrowLeft />
        </button>
      </Link>
    );
  }
};

export default ShowBackArrow;
