import React from "react";

function Locator({ locator, loctype, subtype }) {
  let inner = (
    <span className="text-md ">
      <span className="locatorType ml-3">
        {loctype.charAt(0).toUpperCase()}
        {loctype.slice(1)}:
      </span>
      {locator}
    </span>
  );

  if (loctype === "digital") {
    inner = (
      <span className="locatorType ml-3">
        <a
          className="text-cyan-600"
          href={locator}
          rel="noreferrer"
          target="_blank"
        >
          {subtype.charAt(0).toUpperCase()}
          {subtype.slice(1)}
        </a>
      </span>
    );
  }

  return <span>{inner}</span>;
}

function Note({ note, header }) {
  return (
    <span className="text-md">
      <span className="locatorType ml-5">{header}:</span>
      <span className="text-md ml-3">{note}</span>
    </span>
  );
}

const LOC_FILTER = ["donate", "volunteer"];

export default function Organization({ oid, org, jurisName }) {
  let { locators, notes } = org.meta;
  if (locators) {
    if (jurisName) {
      // state pages needn't include donate or volunteer links
      locators = locators.filter(
        ({ subtype }) => !LOC_FILTER.includes(subtype)
      );
    } else {
      // likewise, the Helping page needn't include anything *but* donate/volunteer/website links
      locators = locators.filter(({ subtype }) => LOC_FILTER.includes(subtype));
    }
  }

  return (
    <div className="sm:text-xs md:text-lg mt-5" id={oid}>
      <p className="font-black text-xl text-center">
        <a
          href={org.website}
          rel="noreferrer"
          target="_blank"
          className="text-cyan-600 mt-5"
        >
          {org.name}
        </a>
      </p>
      <p className=" md:text-lg mt-1">{org.description}</p>

      {notes.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 ml-5 mr-5">
          {notes.map((n) => (
            <Note note={n.note} header={n.extra} />
          ))}
        </div>
      )}

      {locators.length === 0 ? (
        ""
      ) : (
        <div className="ml-5 mt-3">
          {locators.map(({ locator, loctype, subtype }) => (
            <div>
              <span className="ml-3">&mdash;</span>
              <Locator locator={locator} loctype={loctype} subtype={subtype} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
