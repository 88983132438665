import React from "react";
import { useStateContext } from "../contexts/ContextProvider";

const OpenNav = ({ icon }) => {
  const { activeMenu, setActiveMenu } = useStateContext();
  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="z-0 fixed w-8 mt-16 ml-1 rounded-full bg-cyan-800 lg:mt-5  md:ml-2">
      <button
        className="ml-2 mt-2"
        type="button"
        onClick={() => handleActiveMenu()}
      >
        <span className="absolute" />
        {icon}
      </button>
    </div>
  );
};

export default OpenNav;
