import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Sidebar, ShowBackArrow, OpenNav, Organization } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { RiMenu2Fill } from "react-icons/ri";
import { jurisdictionMenu, pathDescriptions } from "../components/MenuData";
import { apiFetch, fetchOrgs } from "../Fetch";

// this needs to be in the same order as the elements should be built
// e.g. it should map the menu order in MenuData.js
const CatMap = [
  // ["DB/API name", "menuElementPath"]
  ["Abortion Provider or Finder", "findClinic"],
  ["", "clinicsToAvoid"],
  ["Financial Assistance", "financialSupport"],
  ["Practical Assistance", "practicalSupport"],
  ["Emotional Support", "emotionalSupport"],
  ["Legal Support", "legalSupport"],
  ["Medical Support", "medicalSupport"],
  ["Digital Safety Resource", "digitalSecurityResources"],
];

const Jurisdiction = () => {
  const params = useParams();
  const location = useLocation();
  const { activeMenu, setActiveMenu, screenSize, setScreenSize } =
    useStateContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize, setScreenSize]);

  useEffect(() => {
    if (screenSize <= 1000) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const [jurisInfo, setJurisInfo] = useState({
    __uninit: true,
    legality: "",
    notes: "",
  });
  const [jurisOrgs, setJurisOrgs] = useState(null);
  const [properName, setProperName] = useState(
    location.state?.properName ?? params.name
  );

  if (jurisInfo.__uninit) {
    apiFetch(`/juris/${params.name}`).then(setJurisInfo);
  }

  if (jurisOrgs === null) {
    setJurisOrgs(undefined); // attempts to avoid a double-load

    console.log(CatMap);
    fetchOrgs(
      params.name,
      location.state?.properName,
      false,
      setJurisOrgs,
      CatMap
    ).then(({ orgs, properName }) => {
      setProperName(properName);
      setJurisOrgs(orgs);
    });
  }

  return (
    <>
      <div className="z-10 flex relative ">
        {activeMenu ? (
          <div className="w-72 fixed sidebar bg-light-grey ">
            <Sidebar location={location} data={jurisdictionMenu} />
          </div>
        ) : (
          <div className="w-0">
            <Sidebar location={location} data={jurisdictionMenu} />{" "}
          </div>
        )}
      </div>
      <div
        className={`z-0 bg-main-bg min-h-screen w-full overflow-x-hidden  ${
          activeMenu
            ? `ml-0 lg:ml-72 blur-2xl lg:blur-none whitespace-pre-wrap `
            : ` flex-2`
        }`}>
        <div>
          <OpenNav
            title="Sidbar"
            icon={<RiMenu2Fill style={{ color: "white" }} />}
          />
        </div>
        <div className="z-0 mt-15 flex-nowrap overflow-auto">
          <ShowBackArrow />
          <div className="flex flex-wrap lg:flex-nowrap justify-center ">
            <p className="mt-8 font-black text-4xl text-cyan-800">
              {properName}
            </p>
          </div>
          <div className="mt-5 font-black text-2xl flex flex-wrap lg:flex-nowrap justify-center">
            Legality: {jurisInfo.legality}
          </div>
          <div className="mt-3  text-left ">
            <p className=" mx-9 md:mx-20 align-left font-bold text-lg  mt-3">
              {" "}
              {jurisInfo.notes}
            </p>
          </div>
          <div className="grid grid-cols-1 divide-y divide-cyan-600">
            <div className="font-black mt-4 text-xl mx-9 md:mx-20 text-cyan-800">
              A Quick Love Note From AAN
            </div>
            <div className="mt-2 text-lg mx-9 md:mx-20 mb-2">
              Hey Friend! We here at AAN want you to know that no matter who you
              are, no matter where you live, if you are a person with a uterus
              who needs an abortion, we care about you. We support you. We trust
              your decision, and we’re here for you. You matter, and you deserve
              quality reproductive care. So, as you embark on this journey, just
              know that we (and so many other people out there) have your back.
              Okay - now let’s direct you to the amazing organizations that will
              help you get the care you need.
            </div>

            {CatMap.map(([catName, entId]) => {
              if (entId === "clinicsToAvoid") {
                return (
                  <div
                    id={entId}
                    className="mt-5 mx-9 md:mx-20 text-lg justify-center ">
                    <div>
                      <p className="font-black text-xl mb-2 text-cyan-800 mt-2">
                        {jurisdictionMenu.find((x) => x.path === entId).label}
                      </p>
                    </div>
                    <div>
                      <div>
                        CPCs have one goal: to keep people from having
                        abortions. They look like legitimate health centers…but
                        they aren’t. Not only do they try to convince people to
                        carry unwanted pregnancies to term, but they fail to
                        follow established medical standards and practices for
                        providing sexual and reproductive healthcare. CPCs are
                        almost always run by anti-abortion religious
                        organizations and do not provide unbiased or medically
                        sound reproductive care.
                      </div>
                      <br />
                      <div className="mb-5">
                        Finding a clinic through one of the clinic finders
                        listed above will help ensure your clinic has been
                        vetted to provide quality abortion care. If you’ve found
                        your clinic outside of a trusted clinic finder or
                        medical provider, it’s a good idea to check the{" "}
                        <a
                          className="text-cyan-600"
                          href="https://crisispregnancycentermap.com/"
                          rel="noreferrer"
                          target="_blank">
                          Crisis Pregnancy Center Map
                        </a>{" "}
                        to make sure it isn’t a CPC.
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div id={entId} className="mt-5 mx-9 md:mx-20 justify-center">
                  <div>
                    <p className="font-black text-xl text-cyan-800 mt-2">
                      {jurisdictionMenu.find((x) => x.path === entId).label}
                    </p>
                    <p className="text-lg font-bold mt-3 mb-2">
                      {pathDescriptions[entId]}
                    </p>
                  </div>
                  <div>
                    {jurisOrgs?.byCategory[catName]?.map((orgId) => {
                      const org = jurisOrgs.byId[orgId];
                      const oid = `${entId}_org_${orgId}`;

                      return (
                        <Organization
                          oid={oid}
                          org={org}
                          jurisName={properName}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Jurisdiction;
