import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import {
  Welcome,
  Jurisdiction,
  Helping,
  About,
  Volunteer,
  Donate,
} from "./pages";

const App = () => {
  return (
    <BrowserRouter>
      <div className="flex relative bg-main-bg">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/jurisdiction/:name" element={<Jurisdiction />} />
          <Route path="/howtohelp" element={<Helping />} />
          <Route path="/about" element={<About />} />
          <Route path="/volunteer" element={<Volunteer />} />
          <Route path="/donate" element={<Donate />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
