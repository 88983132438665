import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Sidebar, ShowBackArrow, OpenNav, Organization } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { RiMenu2Fill } from "react-icons/ri";
import { howToHelpMenu, pathDescriptions } from "../components/MenuData";
import { fetchDonateVolunteerOrgs } from "../Fetch";

// this needs to be in the same order as the elements should be built
// e.g. it should map the menu order in MenuData.js
const CatMap = [
  // ["DB/API name", "menuElementPath"]
  ["Abortion Provider or Finder", "providers"],
  ["Abortion Advocacy", "advocacy"],
  ["Financial Assistance", "fundCare"],
  ["Practical Assistance", "logisticalSupport"],
  ["Emotional Support", "supportEmotional"],
  ["Legal Support", "supportLegal"],
  ["Medical Support", "supportMedical"],
  ["Race and Cause Based Organization", "causeOrgs"],
  ["Digital Safety Resource", "digSecOrgs"],
];

const Helping = () => {
  const { activeMenu, setActiveMenu, screenSize, setScreenSize } =
    useStateContext();

  const location = useLocation();
  const [orgs, setOrgs] = useState(null);

  if (orgs === null) {
    setOrgs(undefined);
    fetchDonateVolunteerOrgs(setOrgs, CatMap).then(({ orgs }) => setOrgs(orgs));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize, setScreenSize]);

  useEffect(() => {
    if (screenSize <= 1000) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  return (
    <>
      <div className="z-10 flex relative ">
        {activeMenu ? (
          <div className="w-72 fixed sidebar bg-light-grey ">
            <Sidebar data={howToHelpMenu} />
          </div>
        ) : (
          <div className="w-0">
            <Sidebar data={howToHelpMenu} />{" "}
          </div>
        )}
      </div>
      <div
        className={`z-0 bg-main-bg min-h-screen w-full ${
          activeMenu
            ? `ml-0 lg:ml-72 blur-2xl lg:blur-none whitespace-pre-wrap`
            : ` flex-2`
        }`}
      >
        <div>
          <OpenNav
            title="Sidbar"
            icon={<RiMenu2Fill style={{ color: "white" }} />}
          />
        </div>
        <div className="z-0 mt-15 flex-nowrap overflow-auto">
          <ShowBackArrow />
          <div className="flex flex-wrap lg:flex-nowrap justify-center ">
            <p className="mt-8 font-black text-4xl text-cyan-800">
              How To Help
            </p>
          </div>
          <div className="grid grid-cols-1 divide-y divide-cyan-600 mt-5 text-left ">
            <p className="mb-8 mx-9 font-bold text-lg md:mx-20">
              {" "}
              If you’re anything like us, you’re filled with rage and ready to
              help. These organizations are looking for donations and/or
              volunteers. So, spin up your Venmo app or pull out your protest
              sign. It’s time to get loud.
            </p>
            <div className="mx-9 md:mx-20 justify-center"></div>
          </div>
          <div className="grid grid-cols-1 divide-y divide-cyan-600">
            {CatMap.map(([catName, entId]) => {
              const { label } = howToHelpMenu.find((x) => x.path === entId);
              return (
                <div id={entId} className=" mx-9 md:mx-20 justify-center ">
                  <div>
                    <p className="font-black text-xl text-cyan-800">{label}</p>
                    <p className=" font-bold text-lg mt-3">
                      {pathDescriptions[entId]}
                    </p>
                  </div>
                  <div>
                    {orgs?.byCategory[catName]?.map((orgId) => {
                      const org = orgs.byId[orgId];
                      const oid = `${entId}_org_${orgId}`;
                      return <Organization oid={oid} org={org} />;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Helping;
