import React from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

const Volunteer = () => {
  return (
    <div className="min-h-screen">
      <Link className="p-4 sm:text-xs md:text-xl" to="/">
        <button className="p-4" type="button">
          <BsArrowLeft />
        </button>
      </Link>
      <div className="flex flex-wrap  ">
        <div className="bg-main-bg   h-44 rounded-xl w-full lg:w-90 p-8 pt-9 m-3 ">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-2xl text-cyan-800">Volunteer</p>
              <p className="  font-body  md:mx-24 sm:text-xs md:text-xl mt-12">
                Help is always welcome. <br /> If you want to volunteer to help
                our organization please reach out. <br /> Email us at{" "}
                <a
                  className="text-cyan-800"
                  href="mailto: info@abortionaccessnow.org?subject=Volunteer"
                >
                  info@abortionaccessnow.org
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Volunteer;
