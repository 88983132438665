/* Our CORS enforcement in the backend requires that requests be sent
  to the same host as the site was loaded from by the client */
const IS_LOCAL = window.location.hostname === "localhost";
const HOST = IS_LOCAL
  ? "dev-tZK0TNNX2toFY1oATFhmBQ.aanow.link"
  : window.location.hostname.replace("www.", "");
const PROT = IS_LOCAL ? "https:" : window.location.protocol;
const API_HOST = `${PROT}//api.${HOST}`;
const CHUNK_RATE_LIMIT_MS = 42;

export async function apiFetch(reqPath) {
  let res;

  try {
    res = await fetch(`${API_HOST}${reqPath}`);
  } catch (fetchErr) {
    console.error(`apiFetch(${reqPath}) threw:`, fetchErr);
    return null;
  }

  if (!res.ok) {
    console.error(`apiFetch(${reqPath}) failed: ${res.status}`);
    return null;
  }

  return res.json();
}

export async function fetchOrgs(
  abbrev,
  properName,
  donateVolunteerOnly,
  setOrgs,
  catMap
) {
  if (!properName || properName.length === 0) {
    const { name } = await apiFetch(`/juris/${abbrev}`);
    properName = name;
  }

  if (!properName) {
    console.error(`Unable to get ${abbrev} properName!?`);
    return null;
  }

  const suffix = donateVolunteerOnly && properName === "All" ? "?dv" : "";
  const orgsList = await apiFetch(`/orgs/${properName}${suffix}`);

  if (!orgsList) {
    console.error(`Unable to get ${properName} orgs list!?`);
    return null;
  }

  const categories = [
    ...orgsList.reduce(
      (a, org) => org.categories.reduce((ai, cat) => ai.add(cat), a),
      new Set()
    ),
  ].reduce((builder, cat) => ({ [cat]: [], ...builder }), {});

  const orgs = {
    byId: orgsList.reduce(
      (a, org) => ({
        [org.id]: {
          meta: {
            locators: [],
            notes: [],
          },
          ...org,
        },
        ...a,
      }),
      {}
    ),
    byCategory: orgsList.reduce((builder, org) => {
      org.categories.forEach((cat) => builder[cat].push(org.id));
      return builder;
    }, categories),
  };

  orgs.byCategory = Object.fromEntries(
    Object.entries(orgs.byCategory).map(([cat, orgsList]) => [
      cat,
      orgsList.sort((orgAId, orgBId) =>
        orgs.byId[orgAId].name.localeCompare(orgs.byId[orgBId].name)
      ),
    ])
  );

  const fetchInChunks = (orgIds) => {
    if (orgIds.length === 0) {
      return;
    }

    // pop the first category chunk and fetch it
    const [chunk] = orgIds;
    apiFetch(`/meta/orgs/${chunk.join(",")}`).then((fetchedChunk) => {
      // set fresh state to integrate
      setOrgs((newOrgs) => {
        // taking care to create a new object rather than the same ref
        const innerOrgs = { ...newOrgs };
        // integrate the chunk of meta into the orgs
        Object.entries(fetchedChunk).forEach(
          ([orgId, meta]) => (innerOrgs.byId[orgId].meta = meta)
        );
        // and schedule the next category (by slicing past the first object we popped off earlier)
        setTimeout(() => fetchInChunks(orgIds.slice(1)), CHUNK_RATE_LIMIT_MS);
        return innerOrgs;
      });
    });
  };

  const catSortOrder = catMap.reduce((a, x, i) => ({ [x[0]]: i, ...a }), {});
  const sortedCatValues = Object.entries(orgs.byCategory)
    .filter(([catName]) => catSortOrder[catName] !== undefined)
    .sort((a, b) => {
      const srA = catSortOrder[a[0]];
      const srB = catSortOrder[b[0]];
      return srA - srB;
    })
    .map((x) => [...x[1]]);

  fetchInChunks(sortedCatValues);

  return {
    orgs,
    properName,
  };
}

export const fetchAllOrgs = fetchOrgs.bind(null, null, "All");
export const fetchDonateVolunteerOrgs = fetchOrgs.bind(null, null, "All", true);
