import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/AANlogo.png";
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineTwitter } from "react-icons/ai";
import { TbBrandTiktok } from "react-icons/tb";
import { BsInstagram } from "react-icons/bs";

const About = () => {
  return (
    <div className="min-h-screen">
      <Link className="p-4 sm:text-xs md:text-xl" to="/">
        <button className="p-4" type="button">
          <BsArrowLeft />
        </button>
      </Link>
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        <div className="bg-main-bg   rounded-xl w-full p-8 pt-9 m-3 ">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-2xl text-cyan-800">About Us</p>
              <p className="md:mx-24 font-bold mt-12 text-xl ">Our Mission</p>
              <p className="md:mx-24 sm:text-xs md:text-xl  mt-3">
                Our mission is to amplify the reach of pro-abortion
                organizations and resources so everyone can find what they need
                to access or support abortion care, no matter who they are or
                where they live.
              </p>
              <p className="font-bold  md:mx-24 mt-12 text-xl ">Our Story</p>
              <p className="md:mx-24 sm:text-xs md:text-xl  mt-3">
                Abortion Access Now (AAN) was founded after SCOTUS' June 24,
                2022, decision to overturn Roe v. Wade, a case that made access
                to abortion care a constitutional right for nearly fifty years.
                Fueled by outrage and a desire to act, we spent hours trying to
                find organizations to volunteer with and donate to. Our
                experience revealed the need for a “one-stop-shop” for abortion
                care resources, donation, and volunteer opportunities. We have
                compiled an extensive database of abortion care resources
                (nearly 200 and growing!) and are ready to link our visitors to
                trusted organizations who will help them get care. We’ve also
                made donation and volunteer opportunities just as easy to find.
                So, whether someone visits our site because they need an
                abortion, or just wants to do what they can to fight for
                reproductive justice and rights, we’re here to show them the
                way. We’re also dedicated to amplifying the voices of the
                amazing organizations who have spent years fighting this fight,
                so make sure to follow our socials for daily repro-rights
                content!
              </p>
              <p className="font-bold text-center mt-12 text-xl">
                Get In Touch:{" "}
                <a
                  className="text-cyan-800"
                  href="mailto: info@abortionaccessnow.org">
                  info@abortionaccessnow.org
                </a>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 justify-items-center">
            <div className="mt-5 w-36 md:w-64 grid grid-cols-3 gap-1 auto-rows-min justify-items-center ">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.tiktok.com/@abortionaccessnow?_t=8UICFWN2zL1&_r=1">
                <button className=" text-3xl rounded-full hover:bg-light-gray">
                  <TbBrandTiktok className="text-cyan-800" />
                </button>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://instagram.com/abortionaccessnow?igshid=NWRhNmQxMjQ=">
                <button className="text-3xl hover:rounded-lg hover:bg-light-gray">
                  <BsInstagram className="text-cyan-800" />
                </button>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://twitter.com/access_abortion">
                <button className="text-4xl rounded-full hover:bg-light-gray">
                  <AiOutlineTwitter className="text-cyan-800" />
                </button>
              </a>
            </div>
          </div>
          <div className="mt-12">
            <img
              className=" md:w-80 sm:w-48 mx-auto"
              src={logo}
              alt="abortion access now"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
