import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import { apiFetch } from "../Fetch";

function StateSelectField({
  name,
  label,
  whatItIs,
  placeholder,
  onSelectChange,
}) {
  const { control } = useFormContext();
  const [jurisdictions, setJurisdictions] = useState([]);

  if (jurisdictions.length === 0) {
    apiFetch("/juris").then(setJurisdictions);
  }

  return (
    <Controller
      defaultValue=""
      label={label}
      name={name}
      required
      control={control}
      render={({ field }) => {
        return (
          <div className="text-zinc-500 relative mx-auto md:w-80">
            {whatItIs}{" "}
            <Select
              className="reactSelect"
              options={jurisdictions}
              {...field}
              placeholder={placeholder}
              onChange={(...a) => onSelectChange(...a)}
            />
          </div>
        );
      }}
    />
  );
}

export default StateSelectField;
