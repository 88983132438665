export const jurisdictionMenu = [
  {
    path: "findClinic",
    label: "Find Your Clinic",
  },
  {
    path: "clinicsToAvoid",
    label: "Avoid Crisis Pregnancy Centers (CPCs)",
  },
  {
    path: "financialSupport",
    label: "Pay For Your Care",
  },
  {
    path: "practicalSupport",
    label: "Find Practical Support",
  },
  {
    path: "emotionalSupport",
    label: "Find Emotional Support",
  },
  {
    path: "legalSupport",
    label: "Find Legal Support",
  },
  {
    path: "medicalSupport",
    label: "Find Medical Support",
  },
  {
    path: "digitalSecurityResources",
    label: "Digital Security Resources",
  },
];

export const howToHelpMenu = [
  {
    path: "providers",
    label: "Abortion Providers",
  },
  {
    path: "advocacy",
    label: "Abortion Advocacy",
  },
  {
    path: "fundCare",
    label: "Financial Support",
  },
  {
    path: "logisticalSupport",
    label: "Practical Support",
  },
  {
    path: "supportEmotional",
    label: "Emotional Support",
  },
  {
    path: "supportLegal",
    label: "Legal Support",
  },
  {
    path: "supportMedical",
    label: "Medical Support",
  },
  {
    path: "causeOrgs",
    label: "Race & Cause Based Organizations",
  },
  {
    path: "digSecOrgs",
    label: "Digital Security Organizations",
  },
];

export const pathDescriptions = {
  findClinic:
    "Whether you’re looking for a surgical or medication abortion, these awesome organizations will help you find in-person care or provide the medication you need for an at home abortion. Some of them (like ineedana and Abortion Finder) will link you to financial and practical support organizations, too! ",
  financialSupport:
    "Ask your clinic or telehealth provider if your private insurance or Medicaid (if you have it) will help cover the cost of your care. If it doesn’t (or if you don’t have it), and you need help paying for your abortion, contact these organizations for financial support.",
  practicalSupport:
    "No matter where you are, you’re not alone! These organizations have people who can help you access your care. If you need help with travel, transportation, childcare, meals, lodging, etc., you can find it through one (or more!) of the organizations below. ",
  emotionalSupport:
    "If you need emotional support before, during, or after your abortion, you’re not alone. There are plenty of people, helplines, resources, and organizations out there to help you. Whether you need pro-choice religious support or just a kind voice to speak to, these resources are here to provide the virtual hug (or maybe even the in-person hug!) you might need.",
  legalSupport:
    "Let’s face it: people with a uterus are under attack. If you live in a state that restricts or criminalizes abortion care, and you’re worried about lawsuits or potentialarrest, these organizations will provide the legal support you need. Many of them also offer Judicial Bypass Support (or help overcoming parental consent and/or notification requirements) for minors who need abortion care.",
  medicalSupport:
    "Abortions are medical procedures, so it makes sense that you might have medical questions. Of course, if you think you’re experiencing a medical emergency, please seek professional care immediately. But if you just have questions about what the surgical or at home (“medication”) abortion processes are like, these resources have the information you’re looking for. Some of them even provide virtual support throughout your abortion.",
  digitalSecurityResources:
    "With the fall of Roe and the rise of aggressive anti-abortion legislation, protecting your digital footprint has become even more important. These amazing organizations, guides, and resources are here to help you protect yourself as you seek reproductive care.",
  providers:
    "Abortion providers are under attack, and some need money to move their practices across state lines. Donating funds or time (think clinic escorts and advocates) is a great way to show them some love.",
  advocacy:
    "There are so many organizations out there that are fighting for reproductive rights and justice. If you want to be a part of the movement, shoot these orgs a PayPal or join their call to action.",
  fundCare:
    "Abortion bans and restrictions disproportionately affect marginalized people and those living in poverty. Some people who desperately need abortion care may not be able to afford it. Luckily there are amazing organizations out there who take yourdonations and turn them into direct financial support for people in need. If you wantto help fund someone’s care, check out some of these orgs.",
  logisticalSupport:
    "The total cost of an abortion includes more than just the procedure. Many people need help organizing and paying for travel, transportation, meals, lodging, childcare, etc. That’s where practical and logistical support organizations come in! These awesome groups either provide - or help organize - logistical services that allow people to access the care they need. Many of them need volunteers. All of them need donations.",
  supportEmotional:
    "Some people need help processing the feelings that can come with getting an abortion. Emotional support organizations provide everything from non-judgmental religious and spiritual support to unbiased and compassionate counseling before, during, and after an abortion. If you’re a skilled listener or pro-choice religious leader, consider donating your time. If you have the money and want to help keep these organizations running, send them some funds.",
  supportLegal:
    "People who get abortions, provide abortions, and advocate for abortion rights are becoming more and more vulnerable to arrest and attack. Luckily, there are organizations out there whose sole purpose is to provide legal support to people who have been charged with abortion related “offenses.” Many of them operate for free. They need your support to keep up the fight. If you’re a pro-choice lawyer, check out their “Join Us” pages, too! ",
  supportMedical:
    "Abortions are medical procedures, and since most people who get them aren’t doctors, it’s common to have medical questions about what is happening and how they’re performed. Some medical support organizations offer amazing resources that walk people through their abortion procedures in an unbiased and supportive way. Others provide abortion care training to doctors and medical students. All of them could use your help.",
  causeOrgs:
    "Although abortion bans and restrictions affect everyone, non-white, non-straight, non-cis, and other marginalized communities are disproportionately affected. Race and cause based organizations exist to help fill those gaps and make sure everyone gets the help, representation, and advocacy they deserve. Help them do this important work by donating your time, your skills, or your dollar bills.",
  digSecOrgs:
    "Digital security has become even more important since Roe fell. States can now useyour digital footprint as proof that you have received an abortion, performed an abortion, or supported an organization that facilitates abortions. These digital security organizations provide essential resources to keep abortion receivers, providers, and advocates safe(r) online. Send them some love (or some bitcoin) as a “thank you.”",
};
